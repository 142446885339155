var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-calendar border"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column",class:{ show: _vm.isCalendarOverlaySidebarActive }},[_c('calendar-sidebar',{attrs:{"is-event-handler-sidebar-active":_vm.isEventHandlerSidebarActive},on:{"update:isEventHandlerSidebarActive":function($event){_vm.isEventHandlerSidebarActive=$event},"update:is-event-handler-sidebar-active":function($event){_vm.isEventHandlerSidebarActive=$event}}})],1),_c('div',{staticClass:"col position-relative"},[_c('div',{staticClass:"card shadow-none border-0 mb-0 rounded-0"},[_c('div',{staticClass:"card-body pb-0"},[_c('full-calendar',{ref:"refCalendar",staticClass:"full-calendar",attrs:{"options":_vm.calendarOptions}})],1)])]),_c('div',{staticClass:"body-content-overlay",class:{ show: _vm.isCalendarOverlaySidebarActive },on:{"click":function($event){_vm.isCalendarOverlaySidebarActive = false}}}),_c('b-modal',{attrs:{"centered":"","title":_vm.lang('t_addEvent'),"max-width":"540"},on:{"ok":_vm.add_record},model:{value:(_vm.isEventHandlerSidebarActive),callback:function ($$v) {_vm.isEventHandlerSidebarActive=$$v},expression:"isEventHandlerSidebarActive"}},[_c('b-form-group',{attrs:{"label":"","label-for":"event-name"}},[_c('b-form-input',{attrs:{"id":"event-name","autofocus":"","trim":"","placeholder":_vm.lang('t_eventName')},model:{value:(_vm.currentData.event_name),callback:function ($$v) {_vm.$set(_vm.currentData, "event_name", $$v)},expression:"currentData.event_name"}})],1),_c('b-form-group',{attrs:{"label":"","label-for":"eventNote"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var color = ref.color;
var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
var color = ref.color;
var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}])},[_c('b-form-input',{attrs:{"id":"eventNote","autofocus":"","trim":"","placeholder":_vm.lang('t_eventNote')},model:{value:(_vm.currentData.event_note),callback:function ($$v) {_vm.$set(_vm.currentData, "event_note", $$v)},expression:"currentData.event_note"}})],1),_c('b-form-group',{attrs:{"label":"","label-for":"number"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var color = ref.color;
var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
var color = ref.color;
var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}])},[_c('b-form-input',{attrs:{"id":"number","autofocus":"","trim":"","placeholder":_vm.lang('t_number')},model:{value:(_vm.currentData.event_contact_number),callback:function ($$v) {_vm.$set(_vm.currentData, "event_contact_number", $$v)},expression:"currentData.event_contact_number"}})],1),_c('b-form-group',{attrs:{"label":"","label-for":"end-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
            dateFormat: 'Y-m-d H:i',
            time_24hr: true,
            enableTime: true,
            allowInput: true,
          },"placeholder":_vm.lang('t_eventDate')},model:{value:(_vm.currentData.event_start_time),callback:function ($$v) {_vm.$set(_vm.currentData, "event_start_time", $$v)},expression:"currentData.event_start_time"}})],1),_c('b-form-group',{attrs:{"label":_vm.lang('t_tags'),"label-for":"event-color"}},_vm._l((_vm.cal_colors),function(item,index){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: item.color }),expression:"{ variant: item.color }",modifiers:{"hover":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(item.description),expression:"item.description",modifiers:{"hover":true,"bottom":true}}],key:index,staticClass:"p-1 d-inline-block rounded mr-1 cursor-pointer",class:[
            ("bg-" + (item.color)),
            { 'border border-light': !index },
            _vm.currentData.event_color == item.color ? 'mark-active' : '' ],on:{"click":function($event){_vm.currentData.event_color = item.color}}})}),0)],1),(_vm.event.extendedProps.agent)?_c('b-modal',{attrs:{"no-close-on-esc":"","no-close-on-backdrop":"","centered":"","title":((_vm.event.extendedProps.agent.split('@')[0]) + " - " + (_vm.event.title) + " - " + (new Date(
        new Date(_vm.event.start || 0).getTime() + 3 * 60 * 60 * 1000
      )
        .toISOString()
        .replace(/([^T]+)T([^\.]+).*/g, '$1 $2'))),"max-width":"540","ok-title":((_vm.lang('t_call')) + ": " + (_vm.event.extendedProps.contact_number)),"cancel-title":_vm.lang('t_displayOnCRM')},on:{"show":_vm.getCustomerDetails,"ok":function($event){return _vm.start_call(_vm.event.extendedProps.contact_number)},"cancel":function($event){return _vm.displayOnCRM(_vm.event.extendedProps.contact_number)}},model:{value:(_vm.isEventDedailtsModalActive),callback:function ($$v) {_vm.isEventDedailtsModalActive=$$v},expression:"isEventDedailtsModalActive"}},[_c('span',[_vm._v(_vm._s(_vm.event.extendedProps.customer_no)+" - "+_vm._s(_vm.event.extendedProps.customer_name))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.event.extendedProps.description))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }